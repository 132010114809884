import { Share } from "@capacitor/share";
import { IonButton, IonContent, IonIcon, IonModal, IonSpinner, IonText } from "@ionic/react";
import { useWorkerFriendLink } from "@src/appV2/Accounts/WorkWithFriends/api/useWorkerFriendLink";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { people } from "ionicons/icons";
import { FC } from "react";

import { IAddFriendModalProps } from "./interface";

export const AddFriendModal: FC<IAddFriendModalProps> = ({ onDismiss }) => {
  const {
    data: workerFriendLinkData,
    isLoading: isWorkerFriendLinkLoading,
    isSuccess: isWorkerFriendLinkSuccess,
    isError: isWorkerFriendLinkError,
  } = useWorkerFriendLink();

  return (
    <IonModal
      isOpen={true}
      onWillDismiss={onDismiss}
      cssClass="add-friend-modal"
      data-testid="add-friends-modal"
    >
      <IonContent className="add-friend-modal-container">
        <div className="add-friend-modal-container-content">
          <IonText className="modal-title">
            <h2>Add Friend</h2>
          </IonText>
          <IonIcon
            mode="ios"
            icon={people}
            size="large"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          <IonText className="modal-subtitle">
            <p>
              Share this link with a friend so you can both see the shifts the other is working.
            </p>
          </IonText>
        </div>
        <div className="add-friend-modal-container-action">
          {isWorkerFriendLinkLoading ? (
            <div aria-label="Loading friend link">
              <IonSpinner color="dark" />
            </div>
          ) : (
            <>
              <IonButton
                expand="full"
                shape="round"
                fill="solid"
                color="primary"
                disabled={isWorkerFriendLinkError || isWorkerFriendLinkLoading}
                onClick={async () => {
                  if (isWorkerFriendLinkSuccess) {
                    logEvent(USER_EVENTS.SHARE_FRIEND_LINK);
                    try {
                      if (isCapacitorPlatform()) {
                        await Share.share({
                          title: "Add Friends on Clipboard",
                          text: "Hey! Want to add me as a friend on Clipboard so we can see the shifts the other has booked and work together? Go here: ",
                          url: workerFriendLinkData.url,
                        });
                      } else {
                        navigator.clipboard.writeText(workerFriendLinkData.url);
                      }
                    } catch (error) {
                      deprecatedDoNotUseLogError({
                        message: `Error while sharing friend link. ${JSON.stringify(
                          error?.stack || error
                        )}`,
                        metadata: {
                          workerFriendLinkData,
                        },
                      });
                    }
                  }
                }}
              >
                Share friend link
              </IonButton>

              <IonButton
                expand="full"
                shape="round"
                className="add-friend-modal-cancel-action"
                fill="outline"
                onClick={onDismiss}
              >
                Cancel
              </IonButton>
            </>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};
